<template>
  <v-row style="margin: 0 !important">
    <v-col cols="12" md="7" class="loginImg"></v-col>
    <v-col cols="12" md="5" class="loginDiv">
      <v-container>
        <div>
          <v-img
            src="/logo.png"
            width="100"
            style="margin: auto"
            class="m-auto"
          />
          <p class="mb-4 login-title">Login to GMB SMS</p>
          <p v-if="loginStatus.value === true" class="error-text">
            {{ loginStatus.message }}
          </p>
          <v-form v-model="valid" @submit="handleLogin">
            <v-container>
              <v-text-field
                v-model="username"
                :rules="nameRules"
                label="User Name"
                style="margin-bottom: 20px"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :rules="[rules.required, rules.special]"
                label="Password"
                type="password"
                required
              ></v-text-field>
              <v-btn
                color="primary"
                @click="handleLogin"
                class="mr-0 mt-4"
                style="margin-top: 20px"
                >Login</v-btn
              >
            </v-container>
          </v-form>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import "./login.scss";
import { mapGetters, mapActions } from "vuex";
import { APIdoGetUser } from "@/api/common/api";

export default {
  name: "Login",

  data() {
    return {
      valid: false,
      username: "",
      password: "",
      nameRules: [(v) => !!v || "Name is required"],
      passwordRules: [(v) => !!v || "Password is required"],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        special: (value) => {
          return /[!@#\$%\^&\*]/.test(value) || "At least 1 special symbol";
        },
        uppercaseEn: (value) => {
          //For latin letters only
          return (
            /[A-Z]/.test(value) || "At least 1 uppercase letter (A-Z only)"
          );
        },
        number: (value) => {
          return /\d/.test(value) || "At least 1 number";
        },
      },

      errorMsg: "",
    };
  },
  computed: {
    ...mapGetters({
      loginStatus: "getLoginStatus",
    }),
  },
  mounted() {
    const loginFlag = localStorage.getItem("user");
    this.doChangeLoginFailueMsg();
    if (loginFlag) {
      APIdoGetUser()
        .then(() => {
          this.$router.push("/app/dashboard");
        })
        .catch(() => {});
    }
  },
  methods: {
    ...mapActions({
      doLogin: "doLogin",
      doChangeLoginFailueMsg: "doChangeLoginFailueMsg",
    }),
    handleLogin() {
      const username = this.username;
      const password = this.password;
      this.doLogin({ username, password });
    },
  },
};
</script>
<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
</style>
